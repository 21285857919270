.users-page {
   ::-webkit-scrollbar {
      height: 5px; // Width of the scrollbar
   }
   ::-webkit-scrollbar-track {
      background: transparent; // Color of the scrollbar track
   }
   ::-webkit-scrollbar-thumb {
   background-color: #0074F0;
 // Color of the scrollbar handle
      border-radius: 4px;
   }
   ::-webkit-scrollbar-thumb:hover {
      background: #ccc; // Color of the scrollbar handle on hover
   }
}