$full-sidbar:280px;
$collapsed-sidbar:80px;
.App {
   main {
      min-height: calc(100vh - 64px);
      transition: all ease-in-out .3s;
      .react-autosuggest__suggestions-container {
         position: absolute;
         z-index: 1000;
         width: 280px;
         max-height: 160px;
         overflow: auto;
         margin-top: 5px;
         border-top: none;
         background-color: white;
         border-radius: 0 0 4px 4px;
         box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      }
   // Autosuggest suggestions list styling
      .react-autosuggest__suggestions-list {
         list-style: none;
         margin: 0;
         padding: 0;
      }
      
      .react-autosuggest__suggestion {
         padding: 0.5rem;
         cursor: pointer;
      
      &:hover,
         &--highlighted {
            background-color: #e9ecef;
         }
      }
   }
   .home-page {
      ::-webkit-scrollbar {
         height: 6px;
      }
      ::-webkit-scrollbar-track {
         background-color: transparent;
      }
      ::-webkit-scrollbar-thumb {
         background-color: #0074F0;
         border-radius: 4px;
      }
   }
}
.swal2-container {
   display: flex;
   align-items: center;
   justify-content: center;
   .swal2-popup {
      max-width: 320px;
      margin-right: $collapsed-sidbar;
      @media (min-width:992px){
         margin-right: $full-sidbar;
      }
   @media (min-width:768px){
      max-width: 450px;
   }
   }
   .popup-in-unautherised {
      margin-right:0px;
      max-width: 400px;
   }
}